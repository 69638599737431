/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from 'react-helmet'

import "./layout.css"
// import favicon from '../images/favicon.png'
import SideNav from "./sideNav"
import footerImage from '../images/C_ayabrown.png'

const Layout = ({ children, title }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      allStrapiCategory {
        edges {
          node {
            strapiId
            name
            menu_image {
              publicURL
            }
            categories {
              name
              menu_image {
                publicURL
              }
            }
            # items {
            #   id
            #   image {
            #     childImageSharp {
            #       fluid(maxWidth: 400) {
            #         ...GatsbyImageSharpFluid
            #       }
            #     }
            #   }
            # }
          }
        }
      }
      strapiEmail {
        email
      }
      strapiShop {
        link
      }
      strapiNavigationLayout {
        Categories {
          category {
            name
            id
            categories
            menu_image {
              publicURL
            }
          }
        }
      }
    }
  `)

  return (
    <>
      {/* <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet> */}
      <SideNav siteTitle={data.site.siteMetadata.title} title={title} data={data}/>
      <div className='container'>
          {children}
        <div>
          <footer id='footer'>
            <img src={footerImage}/>
          </footer>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
