import { Link } from "gatsby"
import { useState, useEffect } from 'react'
import React from "react"

import index from '../images/ayabrown.png'
import menu_1 from '../images/MENU_1.png'
import menu_2 from '../images/MENU_2.png'
import shop from '../images/SHOP.png'
import press from '../images/PRESS.png'
import contact from '../images/CONTACT.png'

const SideNav = ({ siteTitle, title, data }) => {
  const [menu, setMenu] = useState('index')
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  useEffect(() => setMenu(title), [])

  const selectedCategory = data.strapiNavigationLayout.Categories.find(({category}) => category.name === menu)?.category || null;

  return (
    <div 
      id={`nav-bar`}
      className={`${mobileMenuOpen ? 'mobile-open' : ''}`}>
      <img 
        className='hide-mobile mobile-btn' 
        src={mobileMenuOpen ? menu_1 : menu_2}
        onClick={() => {
          setMobileMenuOpen(!mobileMenuOpen)
          setMenu('index')
        }}
      />
    {
      <img 
        onClick={() => setMenu('index')} 
        className='menu-header'
        src={index}>
      </img>
    }
    {
      (menu !== 'index' && menu !== 'press') &&
      <img className='menu-header' src={data.allStrapiCategory.edges.find(edge => edge.node.name === menu)?.node?.menu_image.publicURL}/>
    }
    { menu === 'press' && 
      <img className='menu-header' src={press}/>
    }
    <div style={{textAlign: 'center'}}>
      {menu === 'index' && data.strapiNavigationLayout.Categories.map(({ category }) => (
        category.categories.length > 0 ?
          <img 
            className='menu-option' 
            src={category.menu_image.publicURL} 
            onClick={() => setMenu(category.name)}
            key={category.name}
          />
          :
          <Link to={`/${category.name}`}>
            <img 
              className='menu-option' 
              src={category.menu_image.publicURL} 
              key={category.name}
            />
          </Link>
        )
      )}
      {menu !== 'index' && data.allStrapiCategory.edges.filter(({ node }) => selectedCategory?.categories?.includes(node?.strapiId)).map(({ node }) => (
        <Link to={`/${node.name}`}>
          <img 
            className='menu-option' 
            src={node.menu_image.publicURL} 
            key={node.name}
          />
        </Link>
      ))}
      {menu === 'index' && 
      <Link to='/press'>
        <img 
          className='menu-option' 
          src={press}
        />
      </Link>
      }
      {menu ==='index' && <a href={data.strapiShop?.link}>
        <img 
          className='menu-option' 
          src={shop}
        />
      </a>}
      {menu === 'index' && 
      <a href={`mailto:${data.strapiEmail.email}`}>
        <img 
          className='menu-option' 
          src={contact}
        />
      </a>}
    </div>
    {
      menu != 'index' && 
      <Link>
        <img 
          id='menu-btn' 
          src={menu_2}
          onClick={() => setMenu('index')}
        />
      </Link>
    }
    </div>
  )
}

export default SideNav